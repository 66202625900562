export const environment = {
  production: true,
  appId: 'HotelWebsite',
  websiteUrl: 'https://myresortspune.in',
  
  nzAPIUrl: 'https://testapi.bookonelocal.co.nz/api-bookone',
  inAPIUrl: 'https://api.bookonelocal.in/api-bookone',
  apiUrlAddress: 'https://api.bookonelocal.in/api-address',
  apiUrlPromotion: 'https://api.bookonelocal.in/promotion-api',
  apiLms: 'https://api.bookonelocal.in/api-lms',
  parentOrganisationId: 3,
  dashboardUrl: 'https://app.bookonelocal.in',
  appUrl: 'https://myresortspune.in',
  androidAppUrl: 'https://play.google.com/store/apps/details?id=nz.co.bookonelocal.customer',
  iosAppUrl: 'https://apps.apple.com/us/app/bookone-local/id1528910778',
  country: 'in',
  googleKey: 'AIzaSyD76Ocky2MYrAcI-f9H3XbxzsrSB0TsbJI',
  callbackUrl: 'https://www.myresortspune.in/#/booking-complete'
};
